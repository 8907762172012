<template>
  <NotPermission v-if="!authenticated" />
  <v-container v-else fluid grid-list-lg>
    <Breadcrumbs :routes="[{ name: 'Inicio' }, { name: 'Bodegas' }, { name: 'Movimientos' }]" />
    <v-card>
      <v-toolbar color="grey darken-1" dark card>
        <v-toolbar-title>Movimientos</v-toolbar-title>
        <v-spacer />
        <v-btn :to="{ name: 'crearMovimiento', params: { id: $route.params.id } }" color="primary">
          <svg style="width: 24px; height: 24px; margin-right: 10px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M20 14H14V20H10V14H4V10H10V4H14V10H20V14Z" />
          </svg>
          Agregar Movimiento
        </v-btn>
      </v-toolbar>
      <v-container fluid grid-list-lg>
        <v-layout row wrap>
          <v-flex sm12 md3>
            <v-text-field v-model="search" box label="Buscar" clearable hide-details />
          </v-flex>
          <v-flex sm6 md2>
            <v-text-field v-model="fecha_inicio" label="fecha-inicio" type="date" outline />
          </v-flex>
          <v-flex sm6 md2>
            <v-text-field v-model="fecha_final" label="fecha-final" type="date" outline />
          </v-flex>
          <v-flex sm12 md4>
            <v-btn
              :loading="loading4"
              :disabled="loading4"
              color="primary"
              large
              @click="searchData"
              style="width: 180px"
            >
              <svg style="width: 24px; height: 24px; margin-right: 10px" viewBox="0 0 24 24">
                <path
                  fill="currentColor"
                  d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                />
              </svg>
              Buscar
              <template v-slot:loader>
                <span class="custom-loader">
                  <v-icon light>cached</v-icon>
                </span>
              </template>
            </v-btn>
            <v-btn color="error" large @click="clearSearch">
              <v-icon dark style="margin-right: 10px"> delete </v-icon>
              Limpiar
            </v-btn>
          </v-flex>

          <v-flex xs12>
            <v-data-table
              :headers="[
                { text: 'N°'},
                { text: 'Movimiento ID', value: 'guia_despacho_id' },
                { text: 'Glosa', value: 'glosa' },
                { text: 'Tipo', value: 'tipoMovimiento.tipo' },
                { text: 'Fecha', value: 'created_at' },
                { text: 'Estado', value: 'estado' },
                { text: 'Detalle' },
                { text: 'Anular' },
              ]"
              :items="passengers"
              :loading="loading"
              hide-actions
              :pagination.sync="pagination"
              class="elevation-1"
            >
              <tr slot="items" slot-scope="props">
                <td class="px-3">
                  {{ props.item.id }}
                </td>
                <td class="px-3">
                  {{ props.item.guia_despacho_id }}
                </td>
                <td class="px-3">
                  {{ props.item.glosa }}
                </td>
                <td class="px-3">
                  {{ props.item.tipoMovimiento.tipo }}
                </td>
                <td class="px-3">
                  {{ formatDate(props.item.created_at) }}
                </td>
                <td class="px-3">
                  <v-chip
                    v-if="props.item.estado === true"
                    small
                    color="primary"
                    text-color="white"
                  >
                    Activo
                  </v-chip>
                  <v-chip v-else-if="props.item.estado === false" small> Anulado </v-chip>
                </td>
                <td class="px-3">
                  <v-btn
                    class="ma-0"
                    :to="{ name: 'detalleMovimiento', params: { id: props.item.id } }"
                    small
                    color="info"
                  >
                    Ver
                  </v-btn>
                </td>
                <td class="px-3">
                  <v-btn
                    v-if="props.item.tipoMovimiento.tipo != 'Venta' && props.item.estado === true"
                    class="ma-0"
                    small
                    @click="openModalDeleteMovimiento(props.item)"
                    color="error"
                  >
                    <v-icon small> delete </v-icon>
                    Anular
                  </v-btn>
                </td>
              </tr>
            </v-data-table>

            <div class="text-xs-center pt-2 md4 lg4">
              <v-pagination
                @input="paginationChangeHandler"
                v-model="pagination.pag1"
                :length="pages"
                :total-visible="7"
                circle
              ></v-pagination>
            </div>
          </v-flex>
        </v-layout>
        <v-btn class="ma-0" :to="{ name: 'ListaBodegas' }" small> Volver </v-btn>
      </v-container>
    </v-card>
    <ModalDelete @onChange="getData" />
  </v-container>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import axios from "axios";
import { baseUrlAPI } from "@/config/api";
const HOST = baseUrlAPI;
export default {
  metaInfo() {
    return { title: "Listado de Bodegas" };
  },

  components: {
    Breadcrumbs: () => import("@/components/Breadcrumbs"),
    NotPermission: () => import("@/views/errors/NotPermission"),
    ModalDelete: () => import("@/views/bodega/ModalDeleteMovimiento"),
  },

  data() {
    return {
      search: "",
      fecha_inicio: "",
      fecha_final: "",
      loading4: false,
      passengers: [],
      loading: true,
      pagination: { totalItems: 0, rowsPerPage: 15, pag1: 1 },
    };
  },
  computed: {
    ...mapState({
      movimientos: (state) => state.bodegas.movimientos,
      loadingMovimientos: (state) => state.bodegas.loadingMovimientos,
    }),
    ...mapGetters({
      authenticated: "auth/check",
      user: "auth/user",
    }),
    pages() {
      if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null) {
        return 0;
      }
      return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage);
    },
  },
  // created() {
  //   this.getMovimientos({bodegaId:this.$route.params.id});
  // },
  created() {
    this.getData();
  },

  methods: {
    ...mapActions({
      getMovimientos: "bodegas/getMovimientos",
      replaceCurrentMovimiento: "bodegas/replaceCurrentMovimiento",
      replaceShowModalDeleteMovimiento: "bodegas/replaceShowModalDeleteMovimiento",
    }),
    clearSearch() {
      this.getData();
      this.search = "";
      this.fecha_inicio = "";
      this.fecha_final = "";
    },
    searchData() {
      this.loading4 = true;
      axios
        .get(
          `${HOST}/bodegas/` +
            this.$route.params.id +
            "/movimientos?search=" +
            this.search +
            "&fecha_inicio=" +
            this.fecha_inicio +
            "&fecha_termino=" +
            this.fecha_final
        )
        .then((response) => {
          this.loading4 = false;
          this.passengers = response.data.data;
          this.totalPassengers = response.meta.total;
          this.numberOfPages = response.meta.last_page;
          this.pagination.pag1 = response.data.meta.current_page;
        });
    },
    paginationChangeHandler(pageNumber) {
      this.pagination.pag1 = pageNumber;
      this.getData();
    },
    getData() {
      let url = `${HOST}/bodegas/${this.$route.params.id}/movimientos?page=${this.pagination.pag1}`;
      axios.get(url).then((response) => {
        this.passengers = response.data.data;
        this.loading = false;
        this.pagination.totalItems = response.data.meta.total;
        this.pagination.rowsPerPage = response.data.meta.per_page;
        this.pagination.pag1 = response.data.meta.current_page;
      });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day, hour] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    openModalDeleteMovimiento(movimiento) {
      this.replaceCurrentMovimiento({ movimiento });
      this.replaceShowModalDeleteMovimiento({ status: true });
    },
  },
};
</script>
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
